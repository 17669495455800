import { retrieveTokens } from "@/shared/lib/util.js";
import { decodeJwt, type JWTPayload } from "jose";
import {
  tokenKeys,
  type AuthStorage,
  type OAuthTokens,
  type User,
  type EmptyObject,
  type UnknownObject,
} from "@/types.js";
import { JWT_PAYLOAD_KNOWN_CLAIM_KEYS } from "@/constants.js";

// Function to omit keys from an object
const omitKeys = <K extends keyof T, T extends Record<string, unknown>>(
  keys: K[],
  obj: T,
): Omit<T, K> => {
  const result = { ...obj };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

const parseJWTToType = <T extends UnknownObject = EmptyObject>(
  jwt: string,
): JWTPayload & T => {
  const parseResult = decodeJwt(jwt);
  return parseResult as JWTPayload & T;
};

export async function getUser<T extends UnknownObject = EmptyObject>(
  storage: AuthStorage,
): Promise<User<T> | null> {
  const tokens = await retrieveTokens(storage);
  if (!tokens) return null;

  const parsedToken = parseJWTToType<T>(tokens.id_token);
  // it might be preferable to throw here
  if (!parsedToken.sub) return null;

  // set the user ID from the token sub
  const userWithAdditionalTokenFields = {
    ...(parsedToken as T),
    id: parsedToken.sub,
  };

  // Assumes all information is in the ID token
  // remove the token keys from the user object to stop it getting too large
  return omitKeys(
    [...JWT_PAYLOAD_KNOWN_CLAIM_KEYS, ...tokenKeys],
    userWithAdditionalTokenFields,
  ) as User<T>;
}

export async function getTokens(
  storage: AuthStorage,
): Promise<OAuthTokens | null> {
  const storageData = await retrieveTokens(storage);
  if (!storageData) return null;

  return {
    idToken: storageData.id_token,
    accessToken: storageData.access_token,
    refreshToken: storageData.refresh_token,
  };
}
