import React from "react";

const shimmerKeyframes = `
  @keyframes shimmer {
    0% {
      transform: translate(-328px, 0);
    }
    to {
      transform: translate(328px, 0);
    }
  }
`;

const shimmerStyle = {
  animation: "1s linear infinite both shimmer",
};

export default function SVGLoading(
  { backgroundColor = "#ffffff" } = { backgroundColor: "#ffffff" },
): JSX.Element {
  return (
    <>
      <style>{shimmerKeyframes}</style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 324 28" // Adjusted viewBox height
        height="40" // Adjusted height
        width="100%"
        style={{
          position: "absolute",
          top: -6,
          left: 0,
          borderRadius: "0.5rem", // Added borderRadius
          backgroundColor,
        }}
        className="svg-loading"
      >
        <defs>
          <linearGradient
            id="Gradient-0"
            x2="320"
            y1="15"
            y2="15"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={backgroundColor} />
            <stop offset=".511" stopColor="#9A9DA6" />
            <stop offset="1" stopColor={backgroundColor} />
          </linearGradient>
        </defs>
        <path id="rect" fill={backgroundColor} d="M0 0h328v28H0z" />{" "}
        {/* Adjusted height */}
        <path
          id="shimmer"
          fill="url(#Gradient-0)"
          d="M0 -2h328v34H0z"
          transform="translate(-328)"
          style={shimmerStyle}
        />
      </svg>
    </>
  );
}
