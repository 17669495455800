import { AUTOREFRESH_TIMEOUT_NAME, REFRESH_IN_PROGRESS } from "@/constants.js";
import { retrieveAccessTokenExpiresAt } from "@/shared/lib/util.js";
import { AuthenticationRefresherImpl } from "./AuthenticationRefresherImpl.js";
import type { AuthStorage, Endpoints } from "@/types.js";
import type { AuthConfig } from "@/server/config.js";

export class BrowserAuthenticationRefresher extends AuthenticationRefresherImpl {
  static override async build(
    authConfig: AuthConfig,
    storage: AuthStorage,
    onError: (error: Error) => Promise<void>,
    endpointOverrides?: Partial<Endpoints>,
  ): Promise<BrowserAuthenticationRefresher> {
    const refresher = new BrowserAuthenticationRefresher(
      authConfig,
      storage,
      onError,
      endpointOverrides,
    );
    await refresher.init();

    return refresher;
  }

  protected handleError(error: Error) {
    console.error("BrowserAuthenticationRefresher: Error", error);
    this.clearAutorefresh();
    this.onError(error);
  }

  protected async handleRefresh() {
    try {
      // ensure only one refresh is in progress
      if (localStorage.getItem(REFRESH_IN_PROGRESS) !== "true") {
        localStorage.setItem(REFRESH_IN_PROGRESS, "true");
        await this.refreshTokens();
        await this.setupAutorefresh(); // Reset the timeout after successful refresh
      }
    } catch (error) {
      console.error(
        "BrowserAuthenticationRefresher: Failed to refresh tokens:",
        error,
      );
      // TODO detect if refresh token has expired and if yes then logout
      this.handleError(error as Error);
    }
  }

  async setupAutorefresh() {
    // clear any existing state
    localStorage.removeItem(REFRESH_IN_PROGRESS);

    if (!this.storage) throw new Error("No storage available");
    // Clear any existing timeout
    this.clearAutorefresh();

    // get expires_in
    const now = Math.floor(Date.now() / 1000);
    const expiresAt =
      (await retrieveAccessTokenExpiresAt(this.storage)) || now + 60;

    // Calculate time until expiry (subtract 30 seconds as buffer)
    const bufferTime = 30; // 30 seconds
    const refreshTime = Math.max(0, expiresAt - bufferTime - now); // handle case were token has expired in the past

    const refreshTimeout = setTimeout(() => {
      this.handleRefresh();
    }, 1000 * refreshTime);
    localStorage.setItem(AUTOREFRESH_TIMEOUT_NAME, refreshTimeout.toString());
  }

  clearAutorefresh() {
    // use local storage to store the timeout id so that if multiple instances
    // of the refresher are created they can all clear the same timeout
    const existingTimeout = localStorage.getItem(AUTOREFRESH_TIMEOUT_NAME);
    if (existingTimeout) {
      clearTimeout(Number(existingTimeout));
      localStorage.removeItem(AUTOREFRESH_TIMEOUT_NAME);
    }
  }
}
