import { useEffect, useState, type ReactNode } from "react";

const BlockDisplay = ({ children }: { children: ReactNode }) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // only runs on client
    setIsClient(true);
  }, []);
  // don't render in server-mode as the appearance changes after load
  return !isClient ? null : (
    <div
      id="iframe-block-display-wrapper"
      style={{
        position: "relative",
        left: 0,
        top: 0,
        zIndex: 50,
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
      }}
    >
      <div
        id="iframe-block-display"
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        {children}
      </div>
    </div>
  );
};
export { BlockDisplay };
