import type { AuthStorage } from "@/types.js";
import { EventEmitter } from "eventemitter3";

export class LocalStorageAdapter implements AuthStorage {
  static _emitter: EventEmitter;
  static get emitter(): EventEmitter {
    if (!LocalStorageAdapter._emitter) {
      LocalStorageAdapter._emitter = new EventEmitter();
    }
    return LocalStorageAdapter._emitter;
  }

  async get(key: string): Promise<string> {
    return Promise.resolve(localStorage.getItem(key) || "");
  }

  async set(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }

  async delete(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
}
