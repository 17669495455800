"use client";
import { useUser } from "@/reactjs/hooks/index.js";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type CSSProperties,
} from "react";
import { ButtonContentOrLoader } from "./ButtonContentOrLoader.js";
import { AuthStatus } from "@/types.js";
import { shouldShowLoader } from "./utils.js";

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-chevron-down"
  >
    <path d="m6 9 6 6 6-6" />
  </svg>
);

const ChevronUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-chevron-up"
  >
    <path d="m18 15-6-6-6 6" />
  </svg>
);

const UserButton = ({
  className,
  wrapperClassName,
  style,
  wrapperStyle,
}: {
  className?: string;
  wrapperClassName?: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);
  const { user, signIn, signOut, authStatus, displayMode } = useUser();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [userActionStarted, setUserActionStarted] = useState(false);

  useEffect(() => {
    if (
      [AuthStatus.AUTHENTICATED, AuthStatus.UNAUTHENTICATED].includes(
        authStatus,
      )
    ) {
      setUserActionStarted(false);
    }
  }, [authStatus]);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [isOpen]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (
      buttonRef.current &&
      dropdownRef.current &&
      !buttonRef.current.contains(target) &&
      !dropdownRef.current.contains(target)
    ) {
      setIsOpen(false);
    }
  }, []);

  const handleSignOut = useCallback(async () => {
    setIsOpen(false);
    await signOut();
  }, [signOut]);

  const handleSignIn = useCallback(async () => {
    setIsOpen(false);
    await signIn();
  }, [signIn]);

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);

      window.addEventListener("keydown", handleEscape);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);

      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleClickOutside, handleEscape, isOpen]);

  if (user) {
    return (
      <div
        css={{ position: "relative", width: "auto" }}
        className={wrapperClassName}
        style={wrapperStyle}
        id="civic-dropdown-container"
      >
        <button
          ref={buttonRef}
          css={{
            cursor: "pointer",
            display: "flex",
            minWidth: "10rem",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5rem",
            borderRadius: "9999px",
            border: "1px solid #6b7280",
            padding: "0.75rem 1rem",
            color: "#6b7280",
            transition: "background-color 0.2s",
            "&:hover": {
              backgroundColor: "#f3f4f6",
            },
          }}
          className={className}
          style={style}
          onClick={() => {
            setUserActionStarted(true);
            authStatus !== AuthStatus.SIGNING_OUT &&
              setIsOpen((isOpen) => !isOpen);
          }}
        >
          <>
            {user?.picture ? (
              <span
                css={{
                  position: "relative",
                  display: "flex",
                  height: "1.5rem",
                  width: "1.5rem",
                  flexShrink: 0,
                  gap: "0.5rem",
                  overflow: "hidden",
                  borderRadius: "9999px",
                }}
              >
                <img
                  css={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={user.picture}
                  alt={user?.name || user?.email}
                />
              </span>
            ) : (
              <span css={{ display: "block" }} />
            )}

            <ButtonContentOrLoader
              authStatus={authStatus}
              displayMode={displayMode}
              userActionStarted={userActionStarted}
            >
              {user?.name || user?.email}
            </ButtonContentOrLoader>
            <span
              css={{
                display: "block",
                pointerEvents: "none",
                visibility: shouldShowLoader(authStatus, displayMode)
                  ? "hidden"
                  : "visible",
              }}
            >
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </span>
          </>
        </button>
        <div
          ref={dropdownRef}
          css={
            isOpen
              ? {
                  position: "absolute",
                  left: 0,
                  width: buttonWidth || "auto",
                  marginTop: "0.5rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "white",
                  padding: "0.5rem 0",
                  color: "#6b7280",
                  boxShadow:
                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                  zIndex: 1000,
                }
              : { display: "none" }
          }
        >
          <ul css={{ listStyleType: "none", margin: 0, padding: 0 }}>
            <li>
              <button
                css={{
                  display: "block",
                  width: "100%",
                  padding: "0.5rem 1rem",
                  transition: "background-color 0.2s",
                  background: "none",
                  border: "none",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUserActionStarted(true);
                  !shouldShowLoader(authStatus, displayMode) && handleSignOut();
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f3f4f6")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <button
      ref={buttonRef}
      data-testid="sign-in-button"
      css={{
        cursor: "pointer",
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        background: "none",
        transition: "background-color 0.2s",
        minWidth: "9em", // this stops the button from going too small when in loading mode
        "&:hover": {
          backgroundColor: "#f3f4f6",
        },
      }}
      className={className}
      style={style}
      onClick={() => {
        setUserActionStarted(true);
        !shouldShowLoader(authStatus, displayMode) && handleSignIn();
      }}
    >
      <ButtonContentOrLoader
        authStatus={authStatus}
        displayMode={displayMode}
        userActionStarted={userActionStarted}
      >
        Sign in
      </ButtonContentOrLoader>
    </button>
  );
};

export { UserButton };
