"use client";

import React, { createContext, useCallback, useEffect, useState } from "react";
import type { ReactNode } from "react";
import type {
  AuthStatus,
  AuthStorage,
  DisplayMode,
  ForwardedTokens,
  User,
} from "@/types.js";
import { useSession } from "@/shared/hooks/useSession.js";
import type { AuthContextType } from "@/shared/providers/AuthContext.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";
import { useToken } from "../hooks/useToken.js";
import type { JWTPayload } from "jose";

type UserContent = Record<string, unknown> & JWTPayload;
type UserContextType<T extends UserContent = UserContent> = {
  user: User<T> | null;
} & {
  accessToken?: string | null;
  idToken?: string | null;
  forwardedTokens?: ForwardedTokens;
} & Omit<AuthContextType, "isAuthenticated">;

const UserContext = createContext<UserContextType | null>(null);

const UserProvider = <T extends UserContent>({
  children,
  storage,
  user: inputUser,
  signOut,
  authStatus,
  signIn,
  displayMode,
}: {
  children: ReactNode;
  storage: AuthStorage;
  user: User<T> | null;
  signOut: () => Promise<void>;
  authStatus: AuthStatus;
  signIn: (displayMode?: DisplayMode) => Promise<void>;
  displayMode: DisplayMode;
}) => {
  const { error: authError, isLoading: authLoading } = useSession();
  const { data: session } = useSession();
  const tokens = useToken();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [userError, setUserError] = useState<Error | null>(null);
  const [user, setUser] = useState<User<T> | null>(inputUser);

  const fetchUser = useCallback(async (): Promise<User<T> | null> => {
    if (!session?.idToken) return null;
    const userSession = new GenericUserSession<T>(storage);
    return userSession.get();
  }, [session?.idToken, storage]);

  useEffect(() => {
    if (session?.idToken) {
      setUserLoading(true);
      fetchUser()
        .then((user) => {
          setUserLoading(false);
          setUser((prevUser) => {
            // we only want to update the user if it's set - if a user is passed in, don't assume it is wrong here
            // it could be just the fetchUser returned null for some other reason.
            // TODO consider cleaning this up in general to avoid needing context here.
            return user ?? prevUser;
          });
        })
        .catch((error) => {
          setUserLoading(false);
          setUserError(error);
        });
    } else {
      setUser(null);
    }
  }, [fetchUser, session?.idToken]);

  const isLoading = authLoading || userLoading;
  const error = authError ?? userError;

  // While we are passing a user as a prop _and_ storing it in state,
  // there is the case where the user is not set in the state yet
  // (setState is called but the rerender has not yet happened), but
  // is passed as a prop. In this case, we want to use the prop value.
  // A better solution is to avoid having multiple layers of context.
  // If the user is passed in, we just use that.
  // We should not split user state management across multiple contexts
  const userValue = user ?? inputUser;

  return (
    <UserContext.Provider
      value={{
        ...tokens,
        user: userValue,
        isLoading,
        error,
        signIn,
        signOut,
        authStatus,
        displayMode: displayMode || "iframe",
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export type { UserContextType };

export { UserProvider, UserContext };
