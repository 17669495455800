import { useEffect, useMemo, useState } from "react";
import { useCivicAuthConfig } from "./useCivicAuthConfig.js";
import type { SessionData } from "@/types.js";
import { LocalStorageAdapter } from "@/browser/storage.js";
import { BrowserAuthenticationRefresher } from "../lib/BrowserAuthenticationRefresher.js";

const useRefresh = (session: SessionData | null) => {
  const authConfig = useCivicAuthConfig();
  const storage = useMemo(() => new LocalStorageAdapter(), []);
  const [error, setError] = useState<Error>();
  // setup token autorefresh
  const [refresher, setRefresher] = useState<
    BrowserAuthenticationRefresher | undefined
  >(undefined);

  useEffect(() => {
    if (!authConfig) return;
    const abortController = new AbortController();
    const currentRefresher = refresher;

    const onError = async (error: Error) => {
      console.error("Error refreshing token", error);
      refresher?.clearAutorefresh();
      setError(error);
    };
    BrowserAuthenticationRefresher.build(
      { ...authConfig },
      storage,
      onError,
    ).then((newRefresher) => {
      if (abortController.signal.aborted) return;

      currentRefresher?.clearAutorefresh();
      setRefresher(newRefresher);
    });

    return () => {
      abortController.abort();
      currentRefresher?.clearAutorefresh();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authConfig, storage]); // Only depend on what actually changes

  useEffect(() => {
    if (session?.authenticated) {
      refresher?.setupAutorefresh();
    } else {
      refresher?.clearAutorefresh();
    }

    return () => refresher?.clearAutorefresh();
  }, [refresher, session?.authenticated]);
  return {
    error,
  };
};

export { useRefresh };
