"use client";
import type { ReactNode } from "react";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { BrowserAuthenticationService } from "@/services/AuthenticationService.js";
import type { AuthenticationResolver } from "@/services/types.js";
import { isWindowInIframe } from "@/lib/windowUtil.js";
import type { SessionData } from "@/types.js";
import {
  useCurrentUrl,
  useCivicAuthConfig,
  useWindowFocused,
} from "@/shared/hooks/index.js";
import { LocalStorageAdapter } from "@/browser/storage.js";

export type ClientTokenExchangeSessionProviderOutput = {
  data: SessionData | null;
  error: Error | null;
  isLoading: boolean;
  doTokenExchange: null | ((url: string) => Promise<void>);
};
const defaultSession: ClientTokenExchangeSessionProviderOutput = {
  data: {
    authenticated: false,
    idToken: undefined,
    accessToken: undefined,
    displayMode: "iframe",
  },
  error: null,
  isLoading: false,
  doTokenExchange: null,
};

// Context for exposing session specifically to the TokenProvider
const ClientTokenExchangeSessionContext =
  createContext<ClientTokenExchangeSessionProviderOutput>(defaultSession);

type ClientTokenExchangeSessionContextType = {
  children: ReactNode;
};

const ClientTokenExchangeSessionProvider = ({
  children,
}: ClientTokenExchangeSessionContextType) => {
  const authConfig = useCivicAuthConfig();
  const [authService, setAuthService] = useState<AuthenticationResolver>();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState<SessionData | null>(null);
  const { isWindowFocused } = useWindowFocused();
  const currentUrl = useCurrentUrl();

  // Add a ref to track processed codes
  const processedCodes = useRef(new Set<string>());

  useEffect(() => {
    if (!currentUrl || !authConfig) return;

    const {
      redirectUrl,
      clientId,
      oauthServer,
      scopes,
      logoutRedirectUrl,
      logoutUrl,
    } = authConfig;
    BrowserAuthenticationService.build({
      clientId,
      redirectUrl,
      logoutRedirectUrl,
      logoutUrl,
      oauthServer,
      scopes,
      displayMode: "iframe",
    }).then(setAuthService);
  }, [currentUrl, authConfig]);

  const isInIframe = isWindowInIframe(globalThis.window);

  const doTokenExchange = useCallback(
    async (inUrl: string) => {
      if (!authService) return;
      const url = new URL(inUrl);
      const code = url.searchParams.get("code");
      const state = url.searchParams.get("state");

      // Create a unique key for this code/state combination
      const exchangeKey = `${code}:${state}`;

      // If we've already processed this code, skip it
      if (processedCodes.current.has(exchangeKey)) {
        console.log("Token exchange already processed for this code");
        return;
      }

      if (code && state) {
        try {
          // Mark this code as processed before starting the exchange
          processedCodes.current.add(exchangeKey);
          setIsLoading(true);
          await authService.tokenExchange(code, state);
        } catch (error) {
          setError(error as Error);
          setSession({ authenticated: false });
        }
        setIsLoading(false);
      }
    },
    [authService],
  );

  const onSignIn = useCallback(async () => {
    if (!authService) return;
    const session = await authService.getSessionData();
    setSession(session);
  }, [authService]);

  const onSignOut = useCallback(() => {
    setSession(null);
  }, []);

  useEffect(() => {
    LocalStorageAdapter.emitter.on("signIn", onSignIn);
    LocalStorageAdapter.emitter.on("signOut", onSignOut);
    return () => {
      LocalStorageAdapter.emitter.off("signIn", onSignIn);
      LocalStorageAdapter.emitter.off("signOut", onSignOut);
    };
  }, [onSignIn, onSignOut]);

  useEffect(() => {
    if (!authConfig) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [authConfig]);
  // Handle page load or refocus
  useEffect(() => {
    if (!authConfig || !authService || !currentUrl || isInIframe || isLoading) {
      return;
    }
    const abortController = new AbortController();
    const onPageLoad = async () => {
      // if we have existing tokens, then validate them and return the session data
      // otherwise check if we have a code in the url and exchange it for tokens
      // if we have neither, return undefined
      const existingSessionData = await authService.validateExistingSession();
      if (existingSessionData.authenticated) {
        setSession(existingSessionData);
        return;
      }
      if (
        abortController.signal.aborted ||
        !new URL(currentUrl).searchParams.get("code")
      ) {
        return;
      }
      await doTokenExchange(currentUrl);
    };
    onPageLoad();
    return () => {
      abortController.abort();
    };
  }, [
    authConfig,
    authService,
    currentUrl,
    doTokenExchange,
    isInIframe,
    isLoading,
    isWindowFocused,
    session?.authenticated,
  ]);

  const value = useMemo(
    () => ({
      data: session,
      error,
      isLoading,
      doTokenExchange: authService ? doTokenExchange : null,
    }),
    [session, error, isLoading, authService, doTokenExchange],
  );

  return (
    <ClientTokenExchangeSessionContext.Provider value={value}>
      {children}
    </ClientTokenExchangeSessionContext.Provider>
  );
};

export type { ClientTokenExchangeSessionContextType as SessionContextType };
export {
  ClientTokenExchangeSessionProvider,
  ClientTokenExchangeSessionContext,
};
