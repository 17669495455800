"use client";
import type { Dispatch, ReactNode, RefObject, SetStateAction } from "react";
import React, { createContext, useMemo, useRef, useState } from "react";
import { useIsInIframe } from "@/shared/hooks/useIsInIframe.js";
import type { IframeMode, LoginAppDesignOptions } from "@/types.js";
import { useLocalStorage, useDarkMode } from "usehooks-ts";
import { DARK_BACKGROUND_COLOR, LIGHT_BACKGROUND_COLOR } from "@/constants.js";

export type IframeProviderOutput = {
  iframeRef: RefObject<HTMLIFrameElement> | null;
  logoutIframeRef: RefObject<HTMLIFrameElement> | null;
  setIframeIsVisible: Dispatch<SetStateAction<boolean>>;
  setIframeAborted: Dispatch<SetStateAction<boolean>>;
  iframeAborted: boolean;
  setLogoutIframeIsVisible: Dispatch<SetStateAction<boolean>>;
  iframeIsVisible: boolean;
  logoutIframeIsVisible: boolean;
  iframeMode: IframeMode;
  renderIframe: boolean;
  backgroundColor: string;
  isIframeMounted: boolean;
  setIframeMounted: Dispatch<SetStateAction<boolean>>;
};
const defaultIframe: IframeProviderOutput = {
  iframeRef: null,
  logoutIframeRef: null,
  setIframeIsVisible: () => {},
  setIframeAborted: () => {},
  setLogoutIframeIsVisible: () => {},
  iframeIsVisible: false,
  iframeAborted: false,
  logoutIframeIsVisible: false,
  iframeMode: "modal",
  renderIframe: false,
  backgroundColor: "white",
  isIframeMounted: false,
  setIframeMounted: () => {},
};

// Context for exposing Iframe specifically to the TokenProvider
const IframeContext = createContext<IframeProviderOutput>(defaultIframe);

type IframeContextType = {
  children: ReactNode;
  iframeMode?: IframeMode;
  onIframeMounted?: () => void;
};

const IframeProvider = ({
  children,
  iframeMode = "modal",
}: IframeContextType) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const logoutIframeRef = useRef<HTMLIFrameElement>(null);
  const isInIframe = useIsInIframe();
  const [isIframeMounted, setIframeMounted] = useState(false);
  const [iframeIsVisible, setIframeIsVisible] = useState(false);
  const [iframeAborted, setIframeAborted] = useState(false);
  const [logoutIframeIsVisible, setLogoutIframeIsVisible] = useState(false);
  const [designOptions] = useLocalStorage<LoginAppDesignOptions>(
    `loginAppDesign`,
    { colorMode: "auto" },
  );
  const { isDarkMode } = useDarkMode();
  const loginAppBackgroundColor = useMemo(() => {
    const colorFromWindow = isDarkMode
      ? DARK_BACKGROUND_COLOR
      : LIGHT_BACKGROUND_COLOR;

    const colorMode = designOptions?.colorMode;
    const colorFromStorage =
      colorMode === "dark" ? DARK_BACKGROUND_COLOR : LIGHT_BACKGROUND_COLOR;

    // if the color mode is auto then use the window matchMedia to determine the color mode
    // otherwise use the stored local color mode set from the login-app
    return colorMode && colorMode !== "auto"
      ? colorFromStorage
      : colorFromWindow;
  }, [designOptions, isDarkMode]);

  const renderIframe = iframeMode === "modal" && !isInIframe;

  return (
    <IframeContext.Provider
      value={{
        iframeRef,
        logoutIframeRef,
        setIframeIsVisible,
        iframeAborted,
        setIframeAborted,
        setLogoutIframeIsVisible,
        iframeIsVisible,
        logoutIframeIsVisible,
        iframeMode,
        renderIframe,
        backgroundColor: loginAppBackgroundColor,
        setIframeMounted,
        isIframeMounted,
      }}
    >
      {children}
    </IframeContext.Provider>
  );
};

export type { IframeContextType };
export { IframeProvider, IframeContext };
