import type {
  AuthStorage,
  ForwardedTokensJWT,
  UnknownObject,
  User,
} from "@/types.js";
import { UserStorage } from "@/shared/lib/types.js";
import { convertForwardedTokenFormat } from "@/lib/jwt.js";

export interface UserSession<T extends UnknownObject> {
  get(): Promise<User<T> | null>;
  set(user: User<T> & { forwardedTokens?: ForwardedTokensJWT }): Promise<void>;
}

export class GenericUserSession<T extends UnknownObject>
  implements UserSession<T>
{
  constructor(readonly storage: AuthStorage) {}

  async get(): Promise<User<T> | null> {
    const user = await this.storage.get(UserStorage.USER);
    return user ? JSON.parse(user) : null;
  }

  async set(
    user: (User<T> & { forwardedTokens?: ForwardedTokensJWT }) | null,
  ): Promise<void> {
    const forwardedTokens = user?.forwardedTokens
      ? convertForwardedTokenFormat(user?.forwardedTokens as ForwardedTokensJWT)
      : null;
    const value = user ? JSON.stringify({ ...user, forwardedTokens }) : "";
    await this.storage.set(UserStorage.USER, value);
  }

  async clear(): Promise<void> {
    await this.storage.delete(UserStorage.USER);
  }
}
