"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import { CloseIcon } from "@/shared/components/CloseIcon.js";
import { CivicAuthIframe } from "@/shared/components/CivicAuthIframe.js";
import { useIframe } from "@/shared/hooks/index.js";
import { TOKEN_EXCHANGE_TRIGGER_TEXT } from "@/constants.js";
import { useCivicAuthConfig } from "@/shared/hooks/index.js";
import { useClientTokenExchangeSession } from "@/shared/hooks/index.js";
import { getIframeRef } from "../lib/iframeUtils.js";

type CivicAuthIframeContainerProps = {
  onClose?: () => void;
  closeOnRedirect?: boolean;
};

function NoChrome({
  children,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <div data-testid="civic-iframe-no-chrome" style={{ position: "relative" }}>
      {children}
    </div>
  );
}

export function IframeChrome({
  children,
  onClose,
  isFrameLoaded,
}: {
  children: React.ReactNode;
  onClose?: () => void;
  isFrameLoaded: boolean;
}) {
  const { setIframeAborted } = useIframe();

  return (
    <div
      style={{
        left: 0,
        top: 0,
        zIndex: 50,
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(17, 24, 39, 0.5)",
        backdropFilter: "blur(4px)",
      }}
      onClick={() => {
        setIframeAborted(true);
        onClose?.();
      }}
    >
      <div
        data-testid="iframe-chrome"
        style={{
          position: "relative",
          overflow: "hidden",
          paddingLeft: "0",
          paddingRight: "0",
          paddingBottom: "0",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          width: "20rem",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {isFrameLoaded && (
          <button
            style={{
              position: "absolute",
              right: "1rem",
              top: "2rem",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              backgroundColor: "white",
              padding: "0.25rem",
              color: "#9ca3af",
            }}
            onClick={() => {
              setIframeAborted(true);
              onClose?.();
            }}
          >
            <CloseIcon />
          </button>
        )}

        {children}
      </div>
    </div>
  );
}

const CivicAuthIframeContainer = ({
  onClose,
  closeOnRedirect = true,
}: CivicAuthIframeContainerProps) => {
  const config = useCivicAuthConfig();
  const [tokenExchangeUrl, setTokenExchangeUrl] = useState<string | null>(null);
  const { doTokenExchange } = useClientTokenExchangeSession();
  const { iframeRef, iframeMode, backgroundColor, setIframeMounted } =
    useIframe();
  const [isIframeContentLoaded, setIsIframeContentLoaded] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    setIframeMounted(true);
  }, [setIframeMounted]);

  useEffect(() => {
    if (tokenExchangeUrl) {
      doTokenExchange?.(tokenExchangeUrl);
    }
  }, [doTokenExchange, tokenExchangeUrl]);

  const processIframeUrl = useCallback(() => {
    if (!config) return;
    if (iframeRef && iframeRef.current) {
      const ref = getIframeRef(iframeRef.current)!;
      if (ref.contentWindow) {
        try {
          const iframeUrl = ref.contentWindow.location.href;
          // we know that oauth has finished when the iframe redirects to our redirectUrl
          if (iframeUrl.startsWith(config.redirectUrl)) {
            const iframeBody = ref.contentWindow.document.body.innerHTML;

            // If we're doing a server token exchange, we need to call the server a second time
            // using a fetch so that we're on the same domain and cookies can be sent and read
            // The server will use the presence of the code_verifier cookie to determine whether to do a token exchange or not.
            // On the initial (3rd party) redirect from the auth server, the cookie won't be sent, so the server-side callback route will just render a blank page,
            // and we'll do the exchange request from here, which will include the cookies.
            if (iframeBody.includes(TOKEN_EXCHANGE_TRIGGER_TEXT)) {
              const params = new URL(iframeUrl).searchParams;
              const appUrl = globalThis.window?.location?.origin;
              fetch(
                `${config.redirectUrl}?${params.toString()}&appUrl=${appUrl}`,
              );
            } else {
              // if we're doing token-exchange in the client, we can just set the authResponseUrl
              // to be handled by the auth provider
              // iframeRef.current.setAttribute("src", "");
              setTokenExchangeUrl(iframeUrl);
            }

            if (closeOnRedirect) onClose?.();
            return true; // Successfully processed the URL
          }
        } catch {
          // ignore errors while waiting for redirect
        }
      }
    }
    return false; // Haven't processed the URL yet
  }, [closeOnRedirect, config, iframeRef, onClose]);

  const intervalId = useRef<NodeJS.Timeout>();

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    },
    [onClose],
  );

  // handle Escape
  useEffect(() => {
    window.addEventListener("keydown", handleEscape);

    return () => window.removeEventListener("keydown", handleEscape);
  });

  const handleIframeLoad = useCallback(() => {
    setIsIframeContentLoaded(true);

    const iframeHasUrl = processIframeUrl();
    if (iframeHasUrl && intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [processIframeUrl, intervalId]);

  const WrapperComponent = iframeMode === "embedded" ? NoChrome : IframeChrome;

  return (
    <WrapperComponent onClose={onClose} isFrameLoaded={isIframeContentLoaded}>
      <div
        style={{
          position: "relative",
          height: isIframeContentLoaded ? "auto" : "225px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: isClient ? backgroundColor : "#8E949D",
          borderRadius: "24px",
          width: "100%",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <div
          id="civic-auth-loading-icon-wrapper"
          style={{
            position: "absolute",
            inset: 0,
            display: isIframeContentLoaded ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: isClient ? backgroundColor : "#8E949D",
            borderRadius: "24px",
            height: "225px",
            width: "100%",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <LoadingIcon />
        </div>
        {isClient && (
          <div
            style={{
              visibility: isIframeContentLoaded ? "visible" : "hidden",
              display: "flex",
              height: "100%",
              width: "100%",
              minWidth: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "24px",
              overflow: "hidden",
              backgroundColor,
              transition: "all 0.5s ease-in-out",
            }}
          >
            <CivicAuthIframe
              ref={iframeRef}
              id={"civic-auth-iframe"}
              onLoad={handleIframeLoad}
            />
          </div>
        )}
      </div>
    </WrapperComponent>
  );
};

export type { CivicAuthIframeContainerProps };

export { CivicAuthIframeContainer };
