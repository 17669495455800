import { DEFAULT_AUTH_SERVER } from "@/constants.js";
import type { AuthConfig } from "@/server/config.js";
import type { AuthenticationRefresher } from "@/services/types.js";
import { retrieveTokens } from "@/shared/lib/util.js";
import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";

export abstract class GenericAuthenticationRefresher
  implements AuthenticationRefresher
{
  protected authConfig: AuthConfig | undefined;
  protected storage: AuthStorage | undefined;

  constructor(readonly onError: (error: Error) => Promise<void>) {}

  get oauthServer(): string {
    return this.authConfig?.oauthServer || DEFAULT_AUTH_SERVER;
  }

  abstract refreshAccessToken(
    refreshToken?: string,
  ): Promise<OIDCTokenResponseBody>;

  async getRefreshToken(): Promise<string> {
    if (!this.storage) throw new Error("No storage available");

    const tokens = await retrieveTokens(this.storage);
    if (!tokens?.refresh_token) throw new Error("No refresh token available");
    return tokens.refresh_token;
  }

  async refreshTokens() {
    try {
      return await this.refreshAccessToken();
    } catch (error) {
      console.error(
        "GenericAuthenticationRefresher: Failed to refresh tokens:",
        error,
      );
      await this.onError(error as Error);
      throw error;
    }
  }
}
