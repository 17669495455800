"use client";

import React, { useEffect, useState } from "react";
import { CivicAuthIframe } from "@/shared/components/CivicAuthIframe.js";
import { useIframe } from "@/shared/hooks/index.js";
import { LoadingIcon } from "./LoadingIcon.js";
import { IframeChrome } from "./CivicAuthIframeContainer.js";

type CivicAuthLogoutIframeContainerProps = {
  isLoading?: boolean;
};

const CivicAuthLogoutIframeContainer = ({
  isLoading = false,
}: CivicAuthLogoutIframeContainerProps) => {
  const { logoutIframeRef } = useIframe();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    // only runs on client
    setIsClient(true);
  }, []);
  // don't render the in server-mode as the appearance changes after load
  return !isClient ? null : (
    <IframeChrome isFrameLoaded={true}>
      {isLoading ? (
        <div
          id="civic-auth-loading-icon-wrapper"
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingIcon />
        </div>
      ) : null}
      <CivicAuthIframe
        ref={logoutIframeRef}
        id={"civic-auth-logout-iframe"}
        onLoad={() => {}}
      />
    </IframeChrome>
  );
};

export { CivicAuthLogoutIframeContainer };
