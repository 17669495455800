"use client";

import React, { useEffect, useMemo, useState } from "react";
import { type SessionData } from "@/types.js";
import { AuthContext } from "@/shared/providers/AuthContext.js";
import type { PKCEConsumer } from "@/services/types.js";
import { useSignIn } from "@/shared/hooks/useSignIn.js";
import { useIframe } from "@/shared/hooks/useIframe.js";
import { useCivicAuthConfig } from "@/shared/hooks/useCivicAuthConfig.js";
import { useSession } from "@/shared/hooks/useSession.js";
import { IFrameAndLoading } from "@/shared/components/IFrameAndLoading.js";
import type { AuthProviderProps } from "@/shared/providers/types.js";
import { useIsInIframe } from "@/shared/hooks/useIsInIframe.js";
import { useCurrentUrl } from "@/shared/hooks/useCurrentUrl.js";
import { useRefresh } from "@/shared/hooks/useRefresh.js";
import { getIframeRef } from "@/shared/lib/iframeUtils.js";

// Global this object setup
let globalThisObject;
if (typeof window !== "undefined") {
  globalThisObject = window;
} else if (typeof global !== "undefined") {
  globalThisObject = global;
} else {
  globalThisObject = Function("return this")();
}
globalThisObject.globalThis = globalThisObject;

export type InternalAuthProviderProps = AuthProviderProps & {
  sessionData?: SessionData;
  pkceConsumer?: PKCEConsumer;
};

const AuthProvider = ({
  children,
  onSignIn,
  onSignOut,
  pkceConsumer,
  iframeMode = "modal",
  displayMode = "iframe",
}: InternalAuthProviderProps) => {
  const authConfig = useCivicAuthConfig();
  const { redirectUrl } = authConfig || {};
  const { iframeRef } = useIframe();
  const currentUrl = useCurrentUrl();
  const { signIn, signOut, authStatus, startSignIn } = useSignIn({
    preSignOut: onSignOut,
    pkceConsumer,
    displayMode,
  });

  const [localSessionData, setLocalSessionData] =
    useState<SessionData | null>();

  const {
    data: session,
    error: tokenExchangeError,
    isLoading: tokenExchangeInProgress,
  } = useSession();

  useEffect(() => {
    if (session) {
      setLocalSessionData(session);
      if (session.authenticated) {
        onSignIn?.();
      }
    }
  }, [onSignIn, session]);

  const isAuthenticated = useMemo(() => {
    return !!localSessionData?.idToken;
  }, [localSessionData]);

  useEffect(() => {
    const ref = getIframeRef(iframeRef?.current, true);
    if (
      redirectUrl &&
      !isAuthenticated &&
      ref &&
      displayMode === "iframe" &&
      !currentUrl?.includes("code=")
    ) {
      startSignIn();
    }
  }, [
    currentUrl,
    iframeMode,
    redirectUrl,
    isAuthenticated,
    iframeRef,
    displayMode,
    startSignIn,
  ]);

  const isInIframe = useIsInIframe();
  // if the SDK loads in an iframe, we show the loading spinner as the iframe
  // will be waiting to be minimized
  const isLoading = tokenExchangeInProgress || !authConfig || isInIframe;

  const { error: refreshError } = useRefresh(session);

  useEffect(() => {
    if (refreshError) {
      console.error("Error refreshing token, signingOut...", refreshError);
      signOut();
    }
  }, [refreshError, signOut]);

  const value = useMemo(
    () => ({
      isLoading,
      error: tokenExchangeError as Error | null,
      signOut,
      authStatus,
      isAuthenticated,
      signIn,
      displayMode,
    }),
    [
      isLoading,
      tokenExchangeError,
      signOut,
      authStatus,
      isAuthenticated,
      signIn,
      displayMode,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      <IFrameAndLoading error={tokenExchangeError} isLoading={isLoading} />
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
