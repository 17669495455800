const DEFAULT_SCOPES = [
  "openid",
  "profile",
  "email",
  "forwardedTokens",
  "offline_access",
];
const DEFAULT_AUTH_SERVER = "https://auth.civic.com/oauth";

const DEFAULT_OAUTH_GET_PARAMS = ["code", "state", "iss"];

const DEFAULT_EXPIRES_IN = 3600; // 1 hour in seconds

// The server's callback handler renders this text if it needs the front-end to make an additional token exchange call,
// for the iframe case where cookies are not sent along with the initial redirect.
const TOKEN_EXCHANGE_TRIGGER_TEXT = "sameDomainCodeExchangeRequired";

const TOKEN_EXCHANGE_SUCCESS_TEXT = "serverSideTokenExchangeSuccess";

const DEFAULT_DISPLAY_MODE = "iframe";
const JWT_PAYLOAD_KNOWN_CLAIM_KEYS = [
  "iss",
  "aud",
  "sub",
  "iat",
  "exp",
] as const;

const AUTOREFRESH_TIMEOUT_NAME = "civicAuthAutorefreshTimeout";
const REFRESH_IN_PROGRESS = "civicAuthRefreshInProgress";
const LOGOUT_STATE = "logout_state";
const DARK_BACKGROUND_COLOR = "rgb(30, 41, 59)";
const LIGHT_BACKGROUND_COLOR = "white";
export {
  DEFAULT_SCOPES,
  DEFAULT_OAUTH_GET_PARAMS,
  DEFAULT_DISPLAY_MODE,
  DEFAULT_AUTH_SERVER,
  DEFAULT_EXPIRES_IN,
  TOKEN_EXCHANGE_TRIGGER_TEXT,
  TOKEN_EXCHANGE_SUCCESS_TEXT,
  JWT_PAYLOAD_KNOWN_CLAIM_KEYS,
  AUTOREFRESH_TIMEOUT_NAME,
  REFRESH_IN_PROGRESS,
  DARK_BACKGROUND_COLOR,
  LIGHT_BACKGROUND_COLOR,
  LOGOUT_STATE,
};
