import debug from "debug";

const PACKAGE_NAME = "@civic/auth";

export interface Logger {
  debug(message: string, ...args: unknown[]): void;
  info(message: string, ...args: unknown[]): void;
  warn(message: string, ...args: unknown[]): void;
  error(message: string, ...args: unknown[]): void;
}

class DebugLogger implements Logger {
  private debugLogger: debug.Debugger;
  private infoLogger: debug.Debugger;
  private warnLogger: debug.Debugger;
  private errorLogger: debug.Debugger;

  constructor(namespace: string) {
    // Format: @org/package:library:component:level
    this.debugLogger = debug(`${PACKAGE_NAME}:${namespace}:debug`);
    this.infoLogger = debug(`${PACKAGE_NAME}:${namespace}:info`);
    this.warnLogger = debug(`${PACKAGE_NAME}:${namespace}:warn`);
    this.errorLogger = debug(`${PACKAGE_NAME}:${namespace}:error`);

    this.debugLogger.color = "4";
    this.infoLogger.color = "2";
    this.warnLogger.color = "3";
    this.errorLogger.color = "1";
  }

  debug(message: string, ...args: unknown[]): void {
    this.debugLogger(message, ...args);
  }

  info(message: string, ...args: unknown[]): void {
    this.infoLogger(message, ...args);
  }

  warn(message: string, ...args: unknown[]): void {
    this.warnLogger(message, ...args);
  }

  error(message: string, ...args: unknown[]): void {
    this.errorLogger(message, ...args);
  }
}

export const createLogger = (namespace: string): Logger =>
  new DebugLogger(namespace);

// Pre-configured loggers for different parts of the package
export const loggers = {
  // Next.js specific loggers
  nextjs: {
    routes: createLogger("api:routes"),
    middleware: createLogger("api:middleware"),
    handlers: {
      auth: createLogger("api:handlers:auth"),
    },
  },
  // React specific loggers
  react: {
    components: createLogger("react:components"),
    hooks: createLogger("react:hooks"),
    context: createLogger("react:context"),
  },
  // Shared utilities loggers
  services: {
    validation: createLogger("utils:validation"),
    network: createLogger("utils:network"),
  },
} as const;
