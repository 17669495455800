"use client";
import React from "react";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import type { AuthStatus, DisplayMode } from "@/types.js";
import { shouldShowLoader } from "./utils.js";
import { useIframe } from "@/shared/hooks/useIframe.js";

/**
 * show the loader if the user action has started and the iframe has not been aborted
 * @param {AuthStatus} options.authStatus
 * @param {DisplayMode} options.displayMode
 * @param {boolean} options.userActionStarted
 * @param options.children
 * @returns
 */
export const ButtonContentOrLoader = ({
  authStatus,
  displayMode,
  userActionStarted,
  children,
}: {
  authStatus: AuthStatus;
  displayMode: DisplayMode;
  userActionStarted?: boolean;
  children: React.ReactNode;
}) => {
  const { iframeAborted } = useIframe();
  const showLoader = shouldShowLoader(
    authStatus,
    displayMode,
    userActionStarted && !iframeAborted,
  );
  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        css={{
          visibility: showLoader ? "hidden" : "visible",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </span>
      {showLoader ? (
        <span
          css={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <LoadingIcon width="1.5em" height="1.5em" />
        </span>
      ) : null}
    </div>
  );
};
