import { AuthStatus, type DisplayMode } from "@/types.js";

/**
 * decide whether to show the loader based on the auth status and display mode
 * if the display mode is new_tab, the loader should not be shown
 * if the auth status is authenticating, the loader should be shown if the user
 * has started an action
 * @param {AuthStatus} authStatus
 * @param {DisplayMode} displayMode
 * @param {boolean} userActionStarted
 * @returns {boolean}
 */
export const shouldShowLoader = (
  authStatus: AuthStatus,
  displayMode: DisplayMode,
  userActionStarted?: boolean,
) => {
  if (displayMode === "new_tab") {
    return false;
  }
  if (authStatus === AuthStatus.AUTHENTICATING) {
    if (displayMode === "iframe") {
      return userActionStarted;
    }
    return true;
  }
  if (authStatus === AuthStatus.SIGNING_OUT) {
    return true;
  }
  return false;
};
