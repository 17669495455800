"use client";
import React, { forwardRef, useEffect, useState } from "react";
// import IframeResizer from "@iframe-resizer/react";
import IframeResizer from "iframe-resizer-react";
import SVGLoading from "./SVGLoading.js";
import { useIframe } from "../hooks/useIframe.js";

type CivicAuthIframeProps = {
  onLoad?: () => void;
  id: string;
};

const CivicAuthIframe = forwardRef<HTMLIFrameElement, CivicAuthIframeProps>(
  ({ onLoad, id }, ref) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const { iframeMode, backgroundColor } = useIframe();
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      // only runs on client
      setIsClient(true);
    }, []);
    // don't render on the server as the appearance changes when the iframe is loaded from login-app post-messages
    return !isClient ? null : (
      <div>
        {isLoaded ? null : (
          <span data-testid="iframe-shimmer-loader">
            {iframeMode !== "embedded" && (
              <SVGLoading backgroundColor={backgroundColor} />
            )}
          </span>
        )}
        <IframeResizer
          inPageLinks
          // iframe-resizer-react was downgraded due to a bug in the latest version
          // the license field is not valid for the downgraded version
          // license="1jy4dww5qzv-s54r73oxcn-v59f4kfgfz"
          id={id}
          forwardRef={ref}
          data-testid={"civic-auth-iframe-with-resizer"}
          style={{
            // we don't want the letterbox effect in embedded mode
            // setting a min and max height so the resizer does not jump up/down when the iframe is loaded
            height: iframeMode !== "embedded" ? "26px" : "max-content",
            minHeight: "225px",
            maxHeight: "600px",
            width: "100%",
            border: "none",
            minWidth: "100%",
            backgroundColor,
            transition: "height 0.25s ease-in-out",
            pointerEvents: "auto",
            borderRadius: "24px",
          }}
          checkOrigin={false}
          onLoad={() => {
            setIsLoaded(true);
            onLoad?.();
          }}
          allow="camera; screen-wake-lock"
          allowFullScreen
          // iframe-resizer-react was downgraded due to a bug in the latest version
          // direction="vertical"
          // the 'direction' field is not valid for the downgraded version
          warningTimeout={0}
        />
      </div>
    );
  },
);

CivicAuthIframe.displayName = "CivicAuthIframe";

export type { CivicAuthIframeProps };

export { CivicAuthIframe };
