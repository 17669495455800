"use client";

import { useEffect, useState, type CSSProperties } from "react";
import { AuthStatus, type DisplayMode } from "@/types.js";
import { useUser } from "@/reactjs/hooks/useUser.js";
import { ButtonContentOrLoader } from "./ButtonContentOrLoader.js";

const SignInButton = ({
  displayMode,
  className,
  style,
}: {
  displayMode?: DisplayMode;
  className?: string;
  style?: CSSProperties;
}) => {
  const { signIn, authStatus, displayMode: userDisplayMode } = useUser();
  const [userActionStarted, setUserActionStarted] = useState(false);

  // reset the userActionStarted state if the user logs out or aborts
  useEffect(() => {
    if (
      [AuthStatus.AUTHENTICATED, AuthStatus.UNAUTHENTICATED].includes(
        authStatus,
      )
    ) {
      setUserActionStarted(false);
    }
  }, [authStatus]);
  return (
    <button
      data-testid="sign-in-button"
      css={{
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        transition: "background-color 0.2s",
        minWidth: "9em", // this stops the button from going too small when in loading mode
      }}
      className={className}
      style={style}
      onClick={() => {
        setUserActionStarted(true);
        signIn(displayMode);
      }}
    >
      <ButtonContentOrLoader
        authStatus={authStatus}
        displayMode={displayMode || userDisplayMode}
        userActionStarted={userActionStarted}
      >
        Sign In
      </ButtonContentOrLoader>
    </button>
  );
};

export { SignInButton };
