import { VERSION } from "./version.js";
export { VERSION };
let versionPrinted = false;
export const getVersion = () => VERSION;
// print the version to the browser
export const printVersion = () => {
  if (!versionPrinted) {
    versionPrinted = true;
    if (
      getVersion() &&
      typeof window !== "undefined" &&
      typeof document !== "undefined"
    ) {
      console.log(getVersion());
    }
  }
};
export { BrowserCookieStorage } from "@/shared/lib/BrowserCookieStorage.js";
export { clearTokens } from "@/shared/lib/util.js";
