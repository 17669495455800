"use client";
import { useEffect, useState, type CSSProperties } from "react";
import { useUser } from "@/reactjs/hooks/useUser.js";
import { ButtonContentOrLoader } from "./ButtonContentOrLoader.js";
import { AuthStatus } from "@/types.js";

const SignOutButton = ({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) => {
  const { signOut, authStatus, displayMode } = useUser();
  const [userActionStarted, setUserActionStarted] = useState(false);

  // reset the userActionStarted state if the user logs out or aborts
  useEffect(() => {
    if (
      [AuthStatus.AUTHENTICATED, AuthStatus.UNAUTHENTICATED].includes(
        authStatus,
      )
    ) {
      setUserActionStarted(false);
    }
  }, [authStatus]);
  return (
    <button
      css={{
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        transition: "background-color 0.2s",
      }}
      className={className}
      style={style}
      onClick={() => signOut()}
    >
      <ButtonContentOrLoader
        authStatus={authStatus}
        displayMode={displayMode}
        userActionStarted={userActionStarted}
      >
        Sign Out
      </ButtonContentOrLoader>
    </button>
  );
};

export { SignOutButton };
