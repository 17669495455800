// These are the default exports of the project.
// They are limited by design to ensure that the public API does not expose any internal implementation details.
// Do not change this without thinking carefully about the impact on the client-facing public API.
export type {
  Tokens,
  Endpoints,
  Config,
  User,
  DisplayMode,
  ForwardedTokens,
  AuthStorage,
} from "@/types.js";

export { AuthStatus } from "@/types.js";
export type * from "@/shared/lib/types.js";

export type { UserContextType } from "@/shared/providers/UserProvider.js";
import { VERSION, printVersion } from "@/shared/index.js";
export { createLogger } from "@/lib/logger.js";
printVersion();
export { VERSION };
