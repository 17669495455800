import {
  CookieStorage,
  type CookieStorageSettings,
} from "@/shared/lib/storage.js";
import type { CookieConfig } from "./types.js";

// Ensure only runs in a browser environment
function documentObj() {
  if (typeof globalThis.window !== "undefined") return globalThis.document;
  const stack = new Error().stack;
  throw new Error(
    "Document is not available in this environment:" + JSON.stringify(stack),
  );
}

const split = (separator: string) => (str: string) => str.split(separator);

const cookieStringFromSettings = (settings: CookieStorageSettings): string => {
  let cookieSettings = "";

  if (settings.path) {
    cookieSettings += `Path=${settings.path}; `;
  }
  if (settings.expires) {
    cookieSettings += `Expires=${settings.expires}; `;
  }
  if (settings.secure) {
    cookieSettings += `Secure; `;
  }
  if (settings.httpOnly) {
    // HttpOnly cannot be set from client-side JavaScript, so this clause can be omitted.
    console.warn(
      "HttpOnly cannot be set on client-side cookies. Ignoring this setting.",
    );
  }
  if (settings.sameSite) {
    cookieSettings += `SameSite=${settings.sameSite}; `;
  }
  return cookieSettings.trim();
};
export class BrowserCookieStorage extends CookieStorage {
  constructor(config: Partial<CookieStorageSettings> = {}) {
    super({
      // sensible browser defaults
      secure: false,
      httpOnly: false,
      ...config,
    });
  }

  async get(key: string): Promise<string | null> {
    const encodedValue = documentObj()
      .cookie.split(";")
      .map(split("="))
      .find(([cookieKey]) => cookieKey?.trim() === key)?.[1];

    return encodedValue ? decodeURIComponent(encodedValue) : null;
  }

  async set(
    key: string,
    value: string,
    cookieConfigOverride: Partial<CookieConfig> = {},
  ): Promise<void> {
    const encodedValue = encodeURIComponent(value);
    const settings = { ...this.settings, ...cookieConfigOverride };
    const cookieString = cookieStringFromSettings(settings);
    documentObj().cookie = `${key}=${encodedValue}; ${cookieString}`;
  }

  async delete(key: string): Promise<void> {
    documentObj().cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
