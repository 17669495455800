// the latest version of iframe resizer uses 'getElement' but the old version uses 'getIframeElement'
// the latest version is currently broken so we're temporarily using the old version
type ExtendedIframeElement = HTMLIFrameElement & {
  // getElement: () => HTMLIFrameElement;
  getIframeElement: () => HTMLIFrameElement;
};
export const getIframeRef = (
  iframeRef: HTMLIFrameElement | ExtendedIframeElement | null | undefined,
  allowNull = false,
): HTMLIFrameElement => {
  if (!iframeRef && !allowNull) {
    throw new Error("iframeRef is required for displayMode 'iframe'");
  }
  return (
    (iframeRef as ExtendedIframeElement)?.getIframeElement?.() ?? iframeRef
  );
};
